<template>
  <div class="bet-info">
    <div class="bet-info__result">{{ result }}</div>
    <div v-if="round" class="bet-info__round">Jornada {{ round }}</div>
  </div>
</template>

<script>
export default {
  name: 'BetInfo',
  props: {
    result: {
      type: String,
      default: '',
    },
    round: {
      type: [Number, String],
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.bet-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__result {
    font-family: Circular-Std-Book, sans-serif;
    font-size: 1.25em;
    color: white;
    background-color: #7ed321;
    padding: 0 0.5em;
    border-radius: 1.6px;
  }

  &__round {
    margin-top: 0.2em;
    font-size: 1em;
    font-family: Circular-Std-Book, sans-serif;
    color: #a5a5a5;
  }

  @media screen and (max-width: 508px) {
    font-size: 0.8rem !important;
  }

  @media screen and (max-width: 388px) {
    font-size: 0.7rem !important;
  }

  @media screen and (max-width: 342px) {
    font-size: 0.6rem !important;
  }
}
</style>
